export const PROMO_TEXT = () => (
  <>
    Smarter
    <br />
    eCommerce
    <br />
    Shipping
  </>
);
export const API_INTEGRATION = "About DPD APIs";
export const API_PROMO_TEXT = () => <>DPD APIs</>;
export const API_INTEGRATION_TEXT = () => (
  <>
    <p>
      DPD offers multiple solutions for shipping your parcels - from the
      standard manual input of MyDPD to integrating our web services
      (API&rsquo;s) into your own website.
    </p>
    <p>
      <strong>File import</strong>
    </p>
    <p>
      The Import Facility is simply an upload of Shipment Data from a host
      system directly into MyDPD to create and produce labels. These are usually
      by a file created from your own Order Management System in a .csv or .txt
      format- Please check out this guide on how to import data into MyDPD
      before contacting us.
    </p>
    <p>
      <strong>Application Programming Interface (API)</strong>
    </p>
    <p>
      If you&apos;re looking to seamlessly integrate your solution with our
      shipping-platform or just to customise your experience with DPD,
      API&rsquo;s will be the answer for you.
    </p>
    <p>
      DPD has developed API&rsquo;s for every step of the shipping cycle. For
      instance, at checkout, you may wish to request a list of available
      services to a specific shipping address.
    </p>
    <p>
      Our Shipping API allows you to send shipment information immediately or
      just validate the shipment to be processed at a later stage. We also
      support various printer models for when it comes to producing shipment
      labels.
    </p>
    <p>
      Once you have shipped a parcel with us, you can then use our Tracking API
      to display the parcel status on demand within your system.
    </p>
  </>
);

export const USE_THE_API = () => <>Use the API Portal</>;
export const API_BUTTON_TEXT = "Vist the API Portal";
export const ECOMMERCE_INTEGRATION = "eCommerce Integration";
export const INTEGRATION_TEXT = () => (
  <>
    We&apos;ve developed a new eCommerce shipping solution to manage all your
    deliveries and put you in control of all your shipping orders across
    multiple online platforms. <br /> <br />
    It&apos;s an easy-to-use shipping solution for everyone - you can seamlessly
    link all your marketplace deliveries and manage your shipping in one place!{" "}
    <br /> <br />
    It works with marketplaces such as Amazon, eBay and many more, putting you
    in complete control of all your shipping.
    <br /> <br />
    Access to eCommerce support documents and videos can he found{" "}
    <a href="https://www.dpd.co.uk/lp/mydpd-features/#e-commerce">here</a>
  </>
);
export const INTERESTED = "Are You Interested?";
export const SORRY = "Sorry";
export const ECOMMERCE_DISABLED = () => (
  <>
    eCommerce Integration <br />
    is not available with your <br />
    current user settings
  </>
);
export const USE_THE_ECOMMERCE = () => (
  <>
    Use the eCommerce <br /> shipping platform now
  </>
);
export const ECOMMERCE_COMING_SOON = () => (
  <>
    Our new eCommerce <br /> shipping platform will <br /> be coming soon
  </>
);
export const GET_STARTED = "Get Started";
export const SOMETHINGS_GONE_WRONG = "Something's Gone Wrong";
export const CANCEL = "Cancel";
export const RETRY = "Retry";
export const GENERATE_TOKEN_FAILURE = () => (
  <>
    It looks like we&apos;ve encountered an issue when trying to redirect you to
    our eCommerce platform. <br />
    <br />
    Please retry and if you&apos;re still experiencing issues, please let us
    know.
  </>
);
export const READ_MORE = "Read more.";

export const GENERIC_ERROR_MODAL_BODY = () => (
  <>
    It looks like we&apos;ve encountered an issue. <br />
    <br />
    Please retry and if you&apos;re still experiencing issues, please let us
    know.
  </>
);

// Support
export const SALESFORCE_SUPPORT_QUERY_GENERATE_TOKEN_FAILURE = () => (
  <>
    It looks like we&apos;ve encountered an issue when trying to redirect you to
    our support platform. <br />
    <br />
    Please retry and if you&apos;re still experiencing issues, please let us
    know.
  </>
);

// Titles
export const CONTACT_DETAILS = "Contact Details";
export const PRINTING_SETTINGS = "Printing Settings";
export const COLLECTION_DEFAULTS = "Collection Defaults";
export const SHIPPING_DEFAULTS = "Shipping Defaults";
export const INVOICE_DEFAULTS = "Invoice Defaults";
export const PAPERLESS_SETTINGS = "Paperless settings";

// Common
export const COMPLETE_REQUIRED_FIELDS =
  "Please complete all required fields as indicated *";
export const FIELD_IS_MANDATORY = "Field is Mandatory";
export const SAVE = "Save";
export const MAX_$_CHARACTERS = num => `Max ${num} characters`;
export const UPPERCASE = "uppercase";
export const LOWERCASE = "lowercase";
export const CAPITALIZE = "capitalize";

export const INVALID_TELEPHONE_NUMBER = "Invalid Telephone Number";
export const INVALID_EMAIL = "Invalid Email";
export const FIELD_CANT_BE_EMPTY = "{0} can not be empty";

export const PLEASE_SELECT_$ = item => `Please ${SELECT_$(item)}`;
export const SELECT_$ = item => `Select a ${item}`;

export const INVALID_TIME = "Invalid Time must be (HH:MM)";
export const MUST_BE_A_POSITIVE_WHOLE_NUMBER =
  "Must be a positive whole number";
export const MUST_BE_A_MORE_THAN_$ = number => `Must be more than ${number}`;
export const MUST_BE_WITHIN_RANGE = "Must be within range of 1 to 175";
export const MUST_BE_A_POSITIVE_NUMBER = "Must be a positive number";

export const GBP = "GBP";
export const EUR = "EUR";
export const GB = "GB";
export const IE = "IE";
export const US = "US";

// Snackbars
export const YOUR_CHANGES_HAVE_BEEN_SAVED = "Your changes have been saved";
export const ERROR_SAVING_MSG = "Error saving";
export const FAILED_TO_FETCH = "Failed to fetch";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const FAILED_TO_FETCH_ERROR_MESSAGE_$ = `${FAILED_TO_FETCH} {0}`;

export const COUNTRIES = "countries";
export const CURRENCIES = "currencies";
export const PRODUCTS = "products";
export const SERVICES = "services";
export const PREFERENCES = "preferences";
export const APP_DATA = "app data";

export const AVAILABILITY = "availability";
export const PAGE_DATA = "page data";
export const CHANGES_SAVED = "Your changes have been saved";
export const PRINTER_$_INSTALLED_SUCCESSFULLY = name =>
  `Printer ${name} installed successfully`;
export const TEST_LABEL_HAS_BEEN_SENT_TO_PRINT_QUEUE =
  "Test label has been sent to print queue";
export const PAGE_ERROR = errNum =>
  `This page has ${errNum} errors. See below.`;
export const USER = "user";

export const WELCOME = "Welcome";
export const ACCOUNT_DETAILS = "Account Details";
export const E_COMMERCE = "eCommerce";
export const REPORTS = "Reports";
export const API = "API";
export const SYSTEM_STATUS = "System Status";

// CONTACT DETAILS

// Lables
export const TITLE = "Title";
export const FORENAME = "Forename";
export const SURNAME = "Surname";
export const POSITION = "Position";
export const EMAIL = "Email";
export const TELEPHONE = "Telephone";
export const ACCOUNT = "Account";
// COLLECTION DEFAULTS

// Lables;
export const CLOSING_TIME = "Closing Time (HH:MM)";
export const DO_NOT_PROMPT_FOR_COLLECTION = "Do not prompt for collection";

// INVOICE SETTINGS

// Titles
export const INVOICE_DETAILS = "Invoice Details";
export const SENDER_INVOICE_DETAILS = "Sender Invoice Details";

// Lables
export const INVOICE_SELECTION = "Invoice Selection";
export const INVOICE_TYPE = "Invoice Type";
export const VAT_NUMBER = "VAT Number";
export const DESTINATION_TAX_ID_NUMBER = "Destination Tax ID/Reg N°";
export const FDA_REGISTRATION_NUMBER = "FDA Registration Number";
export const UKIMS_NUMBER = "UKIMS Number";
export const EORI_NUMBER = "EORI Number";
export const PAGE_HEADER = "Page Header";
export const COMPANY_NAME = "Company name";
export const PROPERTY = "Property";
export const ADDRESS_1 = "Address 1";
export const ADDRESS_2 = "Address 2";
export const ADDRESS_3 = "Address 3";
export const ADDRESS_4 = "Address 4";
export const POSTCODE = "Postcode";
export const CONTACT_NAME = "Contact Name";
export const CONTACT_NUMBER = "Contact Number";

// Clues
export const WHEN_NOT_USING_LETTERHRAD_PAPER =
  "When not using letterhead paper";

// Dropdown lables
export const COMMERCIAL = "Commercial";
export const CREATE_OWN_INVOICE = "Create Own Invoice";
export const GENERATE_INVOICE_ONLINE = "Generate Invoice Online";
export const PROFORMA = "Proforma";
export const SELECT_EACH_TIME_INVOICE = "Select each time invoice is required";

// SHIPPING DEFAULTS

// Titles
export const COLLECTION_DEFAULT_DETAILS = "Collection Default Details";
export const SHIPPING_DETAILS = "Shipping Details";
export const DOMESTIC_SHIPPING_DEFAULTS = "Domestic Shipping Defaults";
export const DIMENSION = "Dimension (Air Services Only)";
export const INTERNATIONAL_SHIPPING_DEFAULTS =
  "International Shipping Defaults";
export const SCANNING_DEFAULTS = "Scanning Defaults";

// Lables
export const USE_SHARED_ADDRESS_BOOK = "Use shared address book";
export const USE_SHARED_PRODUCT_BOOK = "Use shared product book";
export const COUNTRY = "Country";
export const SHIPMENT_ADDRESS_BOOK_SEARCH_TYPE =
  "Shipping address book search type";
export const DEFAULT_SHIPMENT_DATE = "Default Shipment Date";
export const ALLOW_PARCELS_TO_BE_SHIPPED =
  "Allow parcels to be shipped on a Saturday/Sunday";
export const DEFAULT_INFORMATION = "Default Information";

export const EXTENDED_LIABILITY = "Extended Liability";
export const EXTENDED_LIABILITY_VALUE = "Extended Liability Value";
export const CURRENCY = "Currency";
export const CONTENT_DESCRIPTION = "Contents Description";

export const PRODUCT = "Product";
export const SERVICE = "Service";
export const NUMBER_OF_ITEMS = "Number Of Items";
export const SENDERS_ADDRESS_ON_LABEL = "Senders address on label";
export const REFERENCE_1_PREFIX = "Reference 1 Prefix";
export const DEFAULT_BULK_REFERENCE = "Default Bulk Reverse-it Reference";

export const WEIGHT = "Weight (kg)";
export const LENGTH = "Length (cm)";
export const WIDTH = "Width (cm)";
export const HEIGHT = "Height (cm)";

export const AUTO_PRINT = "Auto Print";
export const CURSOR_GOES_STRAIGHT_TO_TOTAL_NO_OF_PACKAGES =
  "Cursor goes straight to Total No of Packages";

export const THERMAL_PRINTER_SETTINGS = "Thermal Printer Settings";
export const MYDPD_CUSTOMER_SETTINGS = "MyDPD Customer";
export const LASER_PRINTER_SETTINGS = "Laser Printer Settings";
export const CONFIGURE_USB_PRINTER = "Configure USB Printer";
export const CONFIGURE_BROWSER_USB_PRINTER = "Configure Browser USB Printer";
export const CONFIGURE_PRINT_WORKSTATION = "Configure Print Workstation";
export const TEST_PRINTER = "Test Printer";

export const PRINTING_TO_THERMAL_PRINTER = "Printing to a thermal printer";
export const THERMAL_PRINTER_TYPE = "Thermal Printer Type";
export const THERMAL_PRINTER_VENDOR = "Thermal Printer Vendor";
export const THERMAL_PRINTER_PRODUCT = "Thermal Printer Product";
export const THERMAL_PRINTER_NAME = "Thermal Printer Name";
export const THERMAL_PRINTER_ASSET_NUMBER = "Thermal Printer Asset Number";
export const IF_UNABLE_READ_ASSET_NUMBER =
  "If you are unable to find or cannot read your asset number, please tick this box";

export const CITIZEN_TSC = "Citizen/TSC";
export const ELTRON_ZEBRA = "Eltron/Zebra200dpi";
export const ZEBRA300 = "Zebra 300dpi";

export const $_LABELS_PER_PAGE = num => `${num} Label per page`;

// Dropdown labels
export const SHORT_NAME = "Short Name";
export const ORGANISATION_NAME = "Organisation Name";
export const POST_CODE = "Post Code";
export const YES = "Yes";
export const NO = "No";

export const INSTALLED_PRINTER = "Installed printer";
export const PRINTER_IS_UNAVAILABLE =
  "Printer is unavailable. Make sure you chose correct printer and it's active";
export const PRINTER_REQUEST_SENT = "Request successfully send";
export const PRINTER_INSTALLED_SUCCESSFULLY = "Printer installed successfully";
export const USE_PRINTER_VIA_MYDPD_CUSTOMER = "Use printer via MyDPD Customer";
export const USE_PRINTER_VIA_BROWSER_USB = "Use printer via Browser USB";
export const BROWSER_USB_PRINTING_IS_NOT_SUPPORTED_BY_$ = by =>
  `Browser USB printing is not supported by your ${by}`;

export const ALLOWED_CHARACTERS_$ = characters =>
  `Allowed characters: ${characters.toString()}`;

export const USER_DETAILS_$ = (username, account) =>
  `${username} ${account ? `(Account: ${account})` : ""}`;

export const CLICK_HERE_FOR_TUTORIAL = "Click here for a tutorial.";

export const MUST_BE_BETWEEN_$ = (start, end) =>
  `Must be between ${start}-${end}`;

export const UKIMS_NUMBER_MUST_BE_EQUAL_$ = number =>
  `UKIMS Number length must be equal ${number}`;

export const APPLY_ALL_ACCOUNTS = "Apply to all accounts";

export const LOGO = "LOGO";

export const SIGNATURE = "Signature";

export const NAME = "Name";

export const AGREE_T_C = "Agree T&C";
