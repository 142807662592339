import { Col } from "react-bootstrap";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Card, Legend } from "@dpdgroupuk/mydpd-ui";

import { F, M } from "../../../../constants";
import { withFormController } from "../../../../hocs";
import { UserModel } from "../../../../models";
import { PaperlessActions } from "../../../../store/paperless";
import * as PaperlessSelectors from "./redux/selectors";
import PageContainer from "../../components/PageContainer";
import PaperlessSettings from "./components/PaperlessSettings";
import * as PaperlessValidator from "./redux/validator";

const PaperlessSettingsPage = ({ user, onSubmit, isAgree }) => (
  <PageContainer onClick={onSubmit} saveDisabled={!isAgree}>
    <Legend rightMessage={UserModel.getUserDetailsString(user)} />
    <Card.Stack fluid>
      <Col md={12}>
        <PaperlessSettings />
      </Col>
    </Card.Stack>
  </PageContainer>
);

export default compose(
  withFormController({
    form: F.FORMS_NAMES.PAPERLESS_SETTINGS_FORM,
    onLoad: PaperlessActions.loadPaperlessSettings,
    onSubmit: PaperlessActions.savePaperlessSettings,
    mapDataToForm: data => data,
    validate: PaperlessValidator.validate,
    entityName: M.PAPERLESS_SETTINGS,
  }),
  connect(state => ({
    isAgree: PaperlessSelectors.getPaperlessFormValues(state).agree,
  }))
)(PaperlessSettingsPage);
