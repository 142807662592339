import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";
import { initialize, getFormValues, getFormInitialValues } from "redux-form";

import { F } from "../../constants";
import { userApis } from "../../apis";
import * as PaperlessSelectors from "./selectors";

export const PaperlessActionTypes = createActionTypes("USER", {
  LOAD_PAPERLESS_SETTINGS: createAsyncActionTypes("LOAD_PAPERLESS_SETTINGS"),
  SAVE_PAPERLESS_SETTINGS: createAsyncActionTypes("SAVE_PAPERLESS_SETTINGS"),
  FETCH_PAPERLESS_SETTINGS_USER: createAsyncActionTypes(
    "FETCH_PAPERLESS_SETTINGS_USER"
  ),
  FETCH_ACCOUNT_PAPERLESS_SETTINGS_USER: createAsyncActionTypes(
    "FETCH_ACCOUNT_PAPERLESS_SETTINGS_USER"
  ),
  POST_PAPERLESS_TRADE_ACCOUNT_SETTINGS: createAsyncActionTypes(
    "POST_PAPERLESS_TRADE_ACCOUNT_SETTINGS"
  ),
  DISAGREE_TERMS_AND_CONDITIONS: createAsyncActionTypes(
    "DISAGREE_TERMS_AND_CONDITIONS"
  ),
  PAPERLESS_TRADE_OPTIONS_OUT: createAsyncActionTypes(
    "PAPERLESS_TRADE_OPTIONS_OUT"
  ),
});

export const fetchPaperlessSettingsUser = createAsyncAction(
  userApis.getPaperlessTradeSettings,
  PaperlessActionTypes.FETCH_PAPERLESS_SETTINGS_USER
);

export const getAccountPaperlessTradeSettings = createAsyncAction(
  userApis.getAccountPaperlessTradeSettings,
  PaperlessActionTypes.FETCH_ACCOUNT_PAPERLESS_SETTINGS_USER
);

export const postPaperlessTradeAccountSettings = createAsyncAction(
  userApis.postPaperlessTradeAccountSettings,
  PaperlessActionTypes.POST_PAPERLESS_TRADE_ACCOUNT_SETTINGS
);

export const setPaperlessTradeOptionsOut = createAsyncAction(
  userApis.postPaperlessTradeOptionsOut,
  PaperlessActionTypes.PAPERLESS_TRADE_OPTIONS_OUT
);

export const changeAccount = accountNumber => async (dispatch, getState) => {
  const state = getState();
  const accounts = PaperlessSelectors.getPaperlessAccounts(state);
  const account = accounts.find(
    account => account.accountNumber === accountNumber
  );
  const accountSettings = await dispatch(
    getAccountPaperlessTradeSettings(accountNumber, account.paperlessDate)
  );

  const formState = {
    agree: true,
    account: accountNumber,
    name: accountSettings.data.printedName,
    title: accountSettings.data.title,
    signatureImageKey: accountSettings.data.signatureImageKey,
    signature: accountSettings.data.signatureImageKey && [
      { name: accountSettings.data.signatureImageKey },
    ],
    logoImageKey: accountSettings.data.logoImageKey,
    logo: accountSettings.data.logoImageKey && [
      { name: accountSettings.data.logoImageKey },
    ],
    signatureKey: accountSettings.data.signatureImageKey,
    logoKey: accountSettings.data.logoImageKey,
    allAccounts: false,
  };

  dispatch(
    initialize(F.FORMS_NAMES.PAPERLESS_SETTINGS_FORM, formState, true, {
      keepDirty: true,
      keepValues: true,
    })
  ); //{ keepDirty: true, keepValues: true }

  return formState;
};

export const loadPaperlessSettings = createAsyncAction(
  () => async dispatch => {
    const settings = await dispatch(fetchPaperlessSettingsUser());
    let formValues = {
      agree: settings.data?.some(
        accountSetting => accountSetting.paperlessState
      ),
    };

    if (settings.data.length === 1) {
      const { accountNumber, paperlessDate } = settings.data[0];
      const formState = await dispatch(
        changeAccount(accountNumber, paperlessDate)
      );

      formValues = { ...formValues, ...formState };
    }

    return {
      data: formValues,
    };
  },
  PaperlessActionTypes.LOAD_PAPERLESS_SETTINGS
);

const createFormDataByFormValues = data => {
  const formData = new FormData();

  formData.append("account", data.account);
  formData.append("allAccounts", data.allAccounts);
  formData.append("printedName", data.name);
  formData.append("title", data.title);

  if (data.signature) {
    if (data.signature[0]?.size) {
      formData.append("signatureImage", data.signature[0]);
    } else {
      formData.append("signatureImageKey", data.signatureImageKey);
    }
  }
  if (data.logo) {
    if (data.logo[0]?.size) {
      formData.append("logoImage", data.logo[0]);
    } else {
      formData.append("logoImageKey", data.signatureImageKey);
    }
  }

  return formData;
};

export const savePaperlessSettings = createAsyncAction(
  data => async dispatch => {
    const formData = createFormDataByFormValues(data);

    await dispatch(postPaperlessTradeAccountSettings(formData));
  },
  PaperlessActionTypes.SAVE_PAPERLESS_SETTINGS
);

export const applyAllAccounts = () => (dispatch, getState) => {
  const state = getState();
  const values = getFormValues(F.FORMS_NAMES.PAPERLESS_SETTINGS_FORM)(state);
  const initValues = getFormInitialValues(
    F.FORMS_NAMES.PAPERLESS_SETTINGS_FORM
  )(state);
  const valuesChanges = { agree: true };

  for (let field in values) {
    if (values[field] !== initValues[field]) {
      valuesChanges[field] = values[field];
    }
  }

  if (valuesChanges.signature?.length && !valuesChanges.signature[0].size) {
    delete valuesChanges.signature;
  }

  if (valuesChanges.logo?.length && !valuesChanges.logo[0].size) {
    delete valuesChanges.logo;
  }

  dispatch(initialize(F.FORMS_NAMES.PAPERLESS_SETTINGS_FORM, valuesChanges));
};

export const disagreeTermsAndConditions = createAsyncAction(
  () => async dispatch => {
    await dispatch(setPaperlessTradeOptionsOut());
    dispatch(initialize(F.FORMS_NAMES.PAPERLESS_SETTINGS_FORM, {}, false));
  },
  PaperlessActionTypes.DISAGREE_TERMS_AND_CONDITIONS
);
