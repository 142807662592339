/* eslint-disable no-undef */
import { ApisClient } from "@dpdgroupuk/mydpd-app";

import { LogonLocalApisClient } from "./localApi";

export const baseUrl =
  process.env.REACT_APP_TARGET_PROXY_APIS || "http://localhost:8081";

export const apisClient = new ApisClient({
  fallbackBaseUrl: baseUrl,
});

export const localApis =
  globalThis.logonLocalApisClientProvider || new LogonLocalApisClient();
