import { M } from "../../../../../constants";
import {
  ACCOUNT,
  TITLE,
  NAME,
  SIGNATURE,
  ALL_ACCOUNTS,
  AGREE,
} from "../../../../../constants/forms/paperlessSettings";

import { formatMessage } from "../../../../../util/string";

export const validate = (props, formProps) => {
  const errors = {};

  if (!formProps.initialized) {
    return errors;
  }

  if (!props[ACCOUNT] && !props[ALL_ACCOUNTS]) {
    errors[ACCOUNT] = formatMessage(M.FIELD_CANT_BE_EMPTY, "Field");
  }

  if (!props[AGREE]) {
    errors[AGREE] = formatMessage(M.FIELD_CANT_BE_EMPTY, "Field");
  }

  if (!props[NAME]) {
    errors[NAME] = formatMessage(M.FIELD_CANT_BE_EMPTY, "Field");
  }

  if (!props[TITLE]) {
    errors[TITLE] = formatMessage(M.FIELD_CANT_BE_EMPTY, "Field");
  }
  if (!props[SIGNATURE]) {
    errors[SIGNATURE] = formatMessage(M.FIELD_CANT_BE_EMPTY, "Field");
  }

  return errors;
};
