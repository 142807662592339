import { getFormValues } from "redux-form";
import { createSelector } from "reselect";
import { F } from "../../../../../constants";

export const getPaperlessFormValues = getFormValues(
  F.FORMS_NAMES.PAPERLESS_SETTINGS_FORM
);

export const getData = state => state.app.paperless;

export const getPaperlessAccounts = createSelector(
  getData,
  ({ paperlessAccounts = [] }) => paperlessAccounts
);

export const getForm = createSelector(getData, ({ form = {} }) => form);

export const getFormAccounts = createSelector(
  getPaperlessAccounts,
  paperlessAccounts =>
    paperlessAccounts.map(account => ({
      value: account.accountNumber,
      label: account.accountName,
    }))
);
