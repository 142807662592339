import { PaperlessActionTypes } from "./actions";

export const initialState = {
  paperlessAccounts: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PaperlessActionTypes.FETCH_PAPERLESS_SETTINGS_USER.SUCCESS: {
      return {
        ...state,
        paperlessAccounts: action.payload.data,
      };
    }
    case PaperlessActionTypes.FETCH_ACCOUNT_PAPERLESS_SETTINGS_USER.SUCCESS: {
      return {
        ...state,
        paperlessAccounts: state.paperlessAccounts.map(paperlessAccount => {
          if (paperlessAccount.account === action.payload.data.account) {
            return {
              ...paperlessAccount,
              ...action.payload.data,
            };
          }

          return paperlessAccount;
        }),
      };
    }
    case PaperlessActionTypes.PAPERLESS_TRADE_OPTIONS_OUT.SUCCESS: {
      return {
        ...state,
        paperlessAccounts: state.paperlessAccounts.map(account => ({
          ...account,
          paperlessState: false,
        })),
      };
    }

    default:
      return state;
  }
};
