import { PAPERLESS_SETTINGS } from "./constants";

export const isRestrictedTab = (settings, tabName) => {
  switch (tabName) {
    case PAPERLESS_SETTINGS:
      return settings?.user?.disablePaperlessTrading;
    default:
      return false;
  }
};
