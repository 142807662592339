import { useState, useCallback, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import {
  Card,
  Form,
  FormControl,
  withPrompt,
  withOverlay,
} from "@dpdgroupuk/mydpd-ui";

import {
  APPLY_ALL_ACCOUNTS,
  LOGO,
  SIGNATURE,
  TITLE,
  NAME,
  AGREE_T_C,
  ACCOUNT,
} from "../../../../../constants/strings";
import { PaperlessActions } from "../../../../../store/paperless";
import { F, M } from "../../../../../constants";
import * as S from "../../../../../constants/strings";
import * as PaperlessSettingsFields from "../../../../../constants/forms/paperlessSettings";
import * as PaperlessSelectors from "../redux/selectors";
import * as ImageApi from "../../../../../apis/image";

const fileExtensionAccept = ".png,.jpg,.jpeg";

const PaperlessSettings = ({
  accounts,
  values,
  onSelectAllAccounts,
  onAgreeTermsChange,
  onAccountChange,
}) => {
  const [signatureFileUrl, setSignatureFile] = useState(null);
  const [logoFileUrl, setLogoFile] = useState(null);

  const getImageUrl = useCallback((files, imageType) => {
    if (files) {
      const file = files[0];

      if (!file.lastModified) {
        return ImageApi.getImageUrl(file.name, imageType);
      } else {
        return URL.createObjectURL(file);
      }
    }
    return null;
  }, []);

  useMemo(() => {
    setLogoFile(
      getImageUrl(values[PaperlessSettingsFields.LOGO], "customs_logo_image")
    );
  }, [values[PaperlessSettingsFields.LOGO], setLogoFile, getImageUrl]);

  useMemo(() => {
    setSignatureFile(
      getImageUrl(
        values[PaperlessSettingsFields.SIGNATURE],
        "customs_signature_image"
      )
    );
  }, [
    values[PaperlessSettingsFields.SIGNATURE],
    setSignatureFile,
    getImageUrl,
  ]);

  const accountsValues = useMemo(() => {
    if (accounts > 1) {
      return [
        { label: S.PLEASE_SELECT_$(S.ACCOUNT), value: null },
        ...accounts,
      ];
    }
    return accounts;
  }, [accounts]);

  return (
    <Card>
      <Card.Header>{S.PAPERLESS_SETTINGS}</Card.Header>
      {!accounts.length && (
        <Container className="p-0">
          <Row>
            <Col>Currently there is no available accounts</Col>
          </Row>
        </Container>
      )}
      {!!accounts.length && (
        <Container className="p-0">
          <Row>
            <Col>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Field
                component={Form.Check}
                type="checkbox"
                label={AGREE_T_C}
                name={PaperlessSettingsFields.AGREE}
                id={PaperlessSettingsFields.AGREE}
                maxLength={25}
                onChange={onAgreeTermsChange}
              />
            </Col>
          </Row>
          {values.agree && (
            <>
              <Row>
                <Col sm={4}>
                  <Field
                    component={FormControl.Dropdown}
                    label={ACCOUNT}
                    name={PaperlessSettingsFields.ACCOUNT}
                    values={accountsValues}
                    textTransform={M.UPPERCASE}
                    onChange={onAccountChange}
                    placeholder={M.PLEASE_SELECT_$(M.ACCOUNT)}
                    disabled={values.allAccounts}
                  />
                </Col>
                <Col sm={4}>
                  <Field
                    component={FormControl.Input}
                    label={NAME}
                    name={PaperlessSettingsFields.NAME}
                    required
                    maxLength={25}
                    helperText={M.MAX_$_CHARACTERS(250)}
                    disabled={!values.account && !values.allAccounts}
                  />
                </Col>
                <Col sm={4}>
                  <Field
                    component={FormControl.Input}
                    label={TITLE}
                    name={PaperlessSettingsFields.TITLE}
                    required
                    maxLength={25}
                    disabled={!values.account && !values.allAccounts}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Field
                    component={FormControl.Input}
                    label={SIGNATURE}
                    name={PaperlessSettingsFields.SIGNATURE}
                    type="file"
                    accept={fileExtensionAccept}
                    onBlur={event => event.preventDefault()}
                    disabled={!values.account && !values.allAccounts}
                  />
                </Col>
                <Col sm={6}>
                  <Field
                    component={FormControl.Input}
                    type="file"
                    label={LOGO}
                    name={PaperlessSettingsFields.LOGO}
                    accept={fileExtensionAccept}
                    onBlur={event => event.preventDefault()}
                    disabled={!values.account && !values.allAccounts}
                  />
                </Col>
              </Row>
              {(signatureFileUrl || logoFileUrl) && (
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      {signatureFileUrl && (
                        <img
                          src={signatureFileUrl}
                          width="100px"
                          alt="Signature image"
                        />
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      {logoFileUrl && (
                        <img src={logoFileUrl} width="100px" alt="Logo image" />
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              )}

              <Row>
                <Col sm={4}>
                  <Field
                    component={Form.Check}
                    type="checkbox"
                    label={APPLY_ALL_ACCOUNTS}
                    name={PaperlessSettingsFields.ALL_ACCOUNTS}
                    id={PaperlessSettingsFields.ALL_ACCOUNTS}
                    onChange={onSelectAllAccounts}
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
      )}
    </Card>
  );
};

const mapDispatchToProps = (dispatch, { prompt, overlay }) => ({
  onAgreeTermsChange: event => {
    if (!event.target.checked) {
      event.preventDefault();
      prompt.showConfirmation({
        header: "Lorem Ipsum",
        message: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
        onConfirm: async () => {
          try {
            overlay.show();
            await dispatch(PaperlessActions.disagreeTermsAndConditions());
          } finally {
            overlay.hide();
          }
        },
      });
    }
  },
  onAccountChange: event =>
    dispatch(PaperlessActions.changeAccount(event.target.value)),
  onSelectAllAccounts: event =>
    event.target.checked && dispatch(PaperlessActions.applyAllAccounts()),
});

const mapStateToProps = state => ({
  accounts: PaperlessSelectors.getFormAccounts(state),
  values: PaperlessSelectors.getPaperlessFormValues(state),
});

export default compose(
  withPrompt,
  withOverlay,
  connect(mapStateToProps, mapDispatchToProps)
)(PaperlessSettings);
