import { createSelector } from "reselect";

export const getData = state => state.app.paperless;

export const getPaperlessAccounts = createSelector(
  getData,
  ({ paperlessAccounts = [] }) => paperlessAccounts
);

export const getForm = createSelector(getData, ({ form = {} }) => form);

export const getFormAccounts = createSelector(
  getPaperlessAccounts,
  paperlessAccounts =>
    paperlessAccounts.map(account => ({
      value: account.accountNumber,
      label: account.accountName,
    }))
);
